import classes from "./ReportUploadContent.module.css";
import "@fontsource/roboto";
import InputBlock from "components/UI/inputBlock/InputBlock";
import { useState, useRef } from "react";
import Button from "components/UI/buttons/button/Button";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { useDeviceType } from "components/hooks/useDeviceType";
import TitleMain from "components/UI/titleMain/TitleMain";

const ReportUploadContent = ({
    occasions,
    occasionList,
    setOccasions,
    setOccasionList,
    createReport,
    link,
    copyLinkToBuffer,
    ...props
}) => {
    const [currentOccasion, setCurrentOccasion] = useState({});

    const device = useDeviceType();

    function changeOcassion(data, index) {
        setCurrentOccasion(occasions[index]);
    }

    const ref = useRef();
    return (
        <div ref={ref} className={classes.events__create__style}>
            <TitleMain text={"Выгрузка отчета"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
            <ul>
                {occasions?.length >= 1 &&
                    (device === "phone" ? (
                        <li>
                            <InputBlock
                                localRef={true}
                                placeholder={"Название"}
                                readOnly={true}
                                indexStatus={true}
                                onListStatus={true}
                                list={occasionList}
                                value={currentOccasion?.name ? currentOccasion.name : ""}
                                setValue={changeOcassion}
                            >
                                <img src={require("assets/images/arrow.jpg")} />
                            </InputBlock>
                        </li>
                    ) : (
                        <li>
                            <InputBlock
                                placeholder={"Мероприятие"}
                                localRef={true}
                                readOnly={true}
                                indexStatus={true}
                                onListStatus={true}
                                list={occasionList}
                                value={currentOccasion?.name ? currentOccasion.name : ""}
                                setValue={changeOcassion}
                            >
                                <img src={require("assets/images/arrow.jpg")} />
                            </InputBlock>
                        </li>
                    ))}
                {device === "phone" ? (
                    <li className={classes.li__style}>
                        <InputBlock
                            onClick={() => copyLinkToBuffer(link)}
                            value={link}
                            readOnly={true}
                            placeholder={"Ссылка на сформированный отчет"}
                            onListStatus={false}
                        >
                            <img src={require("assets/icon/copy.jpg")} />
                        </InputBlock>
                    </li>
                ) : (
                    <li className={classes.li__style}>
                        <InputBlock
                            placeholder={"Ссылка на отчет"}
                            onClick={() => copyLinkToBuffer(link)}
                            value={link}
                            readOnly={true}
                            onListStatus={false}
                        >
                            <img src={require("assets/icon/copy.jpg")} />
                        </InputBlock>
                    </li>
                )}
            </ul>
            <div
                style={{ display: "flex", justifyContent: "center", alignItems: "end" }}
            >
                <Button onClick={() => createReport(currentOccasion.id)}>
                    Выгрузить
                </Button>
            </div>
        </div>
    );
};

export default ReportUploadContent;