import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AboutEvent from "components/UI/aboutEvent/AboutEvent";
import OccasionService from 'services/OccasionService';
import { useFetching } from 'components/hooks/useFetching';
import Loader from 'components/UI/loader/Loader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';
import { useState } from "react";
import { changeLoaderStatus } from "redux/slice/loaderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import "styles/css/pop-up-window.css"
import AccountService from "services/AccountService";
import { publickRoutNames } from "router";
import ParticipantService from "services/ParticipantService";
import TicketParticipation from "components/UI/ticketParticipation/TicketParticipation";
import { changePopUpWindowStatus } from "redux/slice/popUpWindowSlice";
import OAuthService from "services/OAuthService";
import { newName } from "redux/slice/titleSlice";
import RoleService from "services/RoleService";
import "styles/css/event.css"
import { setSidebarItem } from "redux/slice/sidebarItemSlice";


function AboutOccasionPage({ route }) {

    const { state } = useLocation();
    const [aboutOccasion, setAboutOccasion] = useState([])
    const popUpWindowStatus = useSelector(state => state.popUpWindow.status)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState({
        id: '', birthday: '', educational_institution: '',
        gender: '', group_class: '', learning_status: '',
        name: '', patronymic: '', phone: '', status: '',
        surname: '', email: '', to_char: ''
    });
    const [role, setRole] = useState([])

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fethcingRole, loadingRole, errorRole] = useFetching(async () => {
        try {
            const res = await RoleService.getRoles()
            setRole(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const [getInfoAccount, loadinggetInfo, errorgetInfo] = useFetching(async (id) => {
        try {
            const res = await AccountService.getAccount(id)
            setUserInfo(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const participantPopUp = () => {
        const { user } = OAuthService.decryptedSync('userData')
        if (user?.id) {
            dispatch(changePopUpWindowStatus(true))
            getInfoAccount(user?.id)
        } else {
            navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
        }
    }

    const [preRegistration, loadingPreRegistration, errorPreRegistration] = useFetching(async (currentRole) => {
        try {
            if (currentRole === undefined || currentRole === null) {
                notificationWarning(`Выберите роль!`)
            }
            else if (userInfo.name.length === 0 || userInfo.surname.length === 0) {
                notificationWarning(`Поля ФИО не должны быть пустыми \n Перейдите в Ваш "Профиль" для заполнения`)
            }
            else {
                const accessToken = localStorage.getItem('accessToken')
                if (!accessToken) {
                    navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
                }
                const res = await ParticipantService.preRegistration({
                    occasion: state.id, participant_role: currentRole.id
                })
                if (res.status === 404) {
                    NotificationManager.warning(res.message, Strings.notificationsWarningTitle, 4000);
                }
                else {
                    NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
                }
                dispatch(changePopUpWindowStatus(false))
                dispatch(changeLoaderStatus(false))
            }
        } catch (error) {
            notificationError(error)
        }
    })


    const [fetchingAboutEvent, loadingAboutEvent, errorAboutEvent] = useFetching(async (id) => {
        try {
            const res = await OccasionService.getAboutOccasions(id)
            await setAboutOccasion(res)
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fethcingRole()
        if (state?.id) {
            fetchingAboutEvent(state.id)
        }
        dispatch(newName(""))

        dispatch(setSidebarItem(Strings.sidebarOccasion))
    }, [])

    return (
        <Loader>
            {popUpWindowStatus &&
                <div className='event-container'>
                    <div className="pop-up">
                        <div className="pop-up__container">
                            <TicketParticipation role={role} preRegistration={preRegistration}
                                userInfo={userInfo} close={() => dispatch(changePopUpWindowStatus(false))} />
                        </div>
                    </div>
                </div>
            }
            <div className={`${popUpWindowStatus && 'pop-up-blure'}`}>
                <AboutEvent participantPopUp={participantPopUp} {...aboutOccasion[0]} listAboutOccasion={aboutOccasion} />
            </div>
        </Loader>
    );
}

export default AboutOccasionPage;