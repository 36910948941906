import classes from "./MarkWork.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef } from 'react'
import Description from "components/UI/description/Description"
import Button from "components/UI/buttons/button/Button"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
// import CircleButtonMark from "components/UI/circleButtonMark/CircleButtonMark"
// import PackMarkButton from "components/UI/circleButtonMark/packMarkButton/PackMarkButton"
import { useDeviceType } from "components/hooks/useDeviceType"
import { useFetching } from "components/hooks/useFetching"
import CommandService from "services/CommandService"
import { NotificationManager } from "react-notifications"
import Strings from "values/Strings"
import { useEffect } from "react"
import { useMemo } from "react"
import RatingJuryService from "services/JuryService"
import Notifications from "components/UI/popupNotifications/Notifications"
import Loader from "components/UI/loader/Loader"
import Table from "rc-table"
import CheckPointService from "services/CheckService"
import { useLocation, useNavigate } from "react-router-dom"
import { publickRoutNames } from "router"
import OAuthService from "services/OAuthService"
import OccasionService from "services/OccasionService"
import { useDispatch } from "react-redux"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import CheckMarkBlock from "../checkMarkBlock/CheckMarkBlock"
import TableForMark from "components/UI/tableForMark/TableForMark"
import AcordionForGrade from "components/UI/acordionForGrade/AcordionForGrade"
import TeamChoice from "components/UI/teamChoice/TeamChoice"
import TeamChoiceButton from "../teamChoiceButton/TeamChoiceButton"
import TitleMain from "components/UI/titleMain/TitleMain";

const MarkWork = () => {
    const ref = useRef()
    const [grades, setGrades] = useState({})
    const device = useDeviceType()
    const navigate = useNavigate()

    const [command, setCommand] = useState([])
    const [commandListPhone, setCommandListPhone] = useState([])
    const [commandList, setCommandList] = useState([])
    const [currentCommand, setCurrentCommand] = useState('')
    const [currentCommandId, setCurrentCommandId] = useState(-1)
    const [numCheck, setNumCheck] = useState(1)
    const [comment, setComment] = useState('')
    const [tableData, setTableData] = useState([])
    const [tableTitle, setTableTitle] = useState([])

    const notificationError = (error) => {
        if (error?.message.length > 0) {
            NotificationManager.error(`${error?.message}`, Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingCommands, loadingCommands, errorCommands] = useFetching(async (num) => {
        try {
            const res = await RatingJuryService.getCurrentCheckPoint(num ? num : numCheck)
            device === 'phone'
                ? setCommandListPhone(res)
                : setCommandList(CommandService.getCurrentCommandsNamesForListDescktop(res))
            setCommand(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingGradesForCommands, loadingGradesCommands, errorGradesCommands] = useFetching(async (command, num) => {
        try {
            const data = `command=${command}&num_check=${num ? num : numCheck}`
            const res = await RatingJuryService.getGradesCurrentCommands(data)
            if (res.grades) {
                setGrades(res.grades)
            }
            if (res.comment) {
                setComment(res.comment)
            }
        } catch (error) {
            notificationError(error)
        }
    }, false)

    const [createOrUpdateRatingJuryCommand, loadingCreateOrUpdateRatingJury, errorCreateOrUpdateRatingJury] = useFetching(async () => {
        try {
            device === 'phone' && setCommandListPhone(old => {
                const getCurrentElementId = old.findIndex(item => item.command_id === currentCommandId)
                old[getCurrentElementId].grades = grades
                return old
            })
            const grade = await RatingJuryService.juryGradeSum(grades)
            const res = await RatingJuryService.createOrUpdateRatingJuryCommand({ comment, grade, grades, command: currentCommandId, num_check: numCheck })
            res && NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500)
            featchingTitalRatingJury()
        } catch (error) {
            notificationError(error)
        }
    })

    const clear = () => {
        setGrades({})
        setComment('')
    }

    const dispatch = useDispatch()

    const [featchingTitalRatingJury, loadingTitalRatingJury, errorTitalRatingJury] = useFetching(async (num = false) => {
        try {
            const res = await CheckPointService.getCheckMark(num ? num : numCheck)
            res && setTableData(res.map((item, i) => ({ name: item.name, grade: item.grade, ...item.grades, key: i })))
        } catch (error) {
            navigate(`/${publickRoutNames.main}`)
            error.message = "Мероприятий пока нет!"
            notificationError(error)
        }
    })

    const [titlesData, setTitlesData] = useState([])

    const [occasion, setOccasion] = useState(null)
    const [getCurrentOccasion, loadingCurrentOccasion, errorCurrentOccasion] = useFetching(async () => {
        try {
            const { part } = OAuthService.decryptedSync('userData')
            const res = await OccasionService.getOccasionById(part?.occasion_id)
            setOccasion(res)

            const tableTitleData = JSON.parse(res.check_point_questions).map(item => item.name)
            setTitlesData(tableTitleData)

            setTableTitle([
                {
                    title: 'Команда',
                    dataIndex: 'name',
                    key: 'name',
                },
                ...(device === 'desktop')
                    ? tableTitleData.map((item, i) => ({
                        title: item,
                        dataIndex: `m${i + 1}`,
                        key: `m${i + 1}`,
                    }))
                    : tableTitleData.map((item, i) => ({
                        title: i + 1,
                        dataIndex: `m${i + 1}`,
                        key: `m${i + 1}`,
                    }))
                ,
                {
                    title: 'Итоговая',
                    dataIndex: 'grade',
                    key: 'grade',
                }
            ])
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })


    const saveResult = () => {
        (currentCommandId && currentCommandId !== -1) && createOrUpdateRatingJuryCommand()
    }

    useEffect(() => {
        getCurrentOccasion()
        fetchingCommands()
        featchingTitalRatingJury()
    }, [])


    const handleBeforeUnload = (event) => {
        saveResult()
        event.preventDefault();
        event.returnValue = '';
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [grades])

    const [popUpWindowForChoice, setPopUpWindowForChoice] = useState(false)
    const choiceCommand = (_, i) => {
        setPopUpWindowForChoice(false)
        clear()
        fetchingProfileMentor(command[i]?.command_id)
        setCurrentCommandId(command[i]?.command_id)
        setCurrentCommand(commandList.length > 0 ? commandList[i] : commandListPhone[i])
        fetchingGradesForCommands(command[i]?.command_id)
    }


    const choiceNumCheck = (event) => {
        clear()
        const num = parseInt(event.target.value)
        featchingTitalRatingJury(num)
        setNumCheck(num)
        fetchingCommands(num)
        if (currentCommandId) {
            fetchingGradesForCommands(currentCommandId, event.target.value)
        }
    }
    // -----------------------------------------Acordion

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const [accordionBlock, setAccordionBlock] = useState(null)

    const [fetchingProfileMentor, loadingProfileMentor, errorProfileMentor] = useFetching(async (data) => {
        try {

            const mass = []
            const elementFormap = []
            const res = await CheckPointService.getCommandCaseInfo(data)
            res.forEach(element => {
                mass.push(element["id_command"])
            });
            const uniqueMass = [...new Set(mass)];

            uniqueMass.forEach(element => {
                elementFormap.push(res.filter(obj => obj.id_command === element))
            });

            setAccordionBlock(
                elementFormap.map((value, key) => <AcordionForGrade table={value} presentation={value[0]["presentation"]} key={`${value[0]?.id_command
                    }_${key}`} addational_material={value[0]["additional_material"]} caseName={value[0]["title"]} caseCategory={value[0]["category"]} caseSpecification={value[0]["specification"]} caseExpected={value[0]["expected_result"]} />)
            )
        } catch (error) {
            notificationWarning(error)
        }
    })

    // ------------------------------------------------

    return (
        <Loader>
            {popUpWindowForChoice
                ? <TeamChoice setPopUpWindowForChoice={setPopUpWindowForChoice} gradeLength={titlesData.length}
                    value={currentCommand} commandList={commandListPhone} choiceCommand={choiceCommand} />
                : <div ref={ref} className={classes.events__create__style}>
                    <TitleMain text={"Контрольная точка"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                    {/* <h3 className={classes.h3__style}>Контрольная точка</h3> */}
                    <div className={classes.input__block}>
                        {
                            occasion && Array.from({ length: occasion?.check_point_count }, (_, i) => (
                                <div className={classes.form_radio_btn} key={i + 1}>
                                    <input onChange={choiceNumCheck} id={`radioB-${i + 1}`} type="radio" name="radioB" value={i + 1} checked={numCheck === i + 1} />
                                    <label htmlFor={`radioB-${i + 1}`}>Контрольная точка №{i + 1}</label>
                                </div>))
                        }
                    </div>
                    {device === "phone"
                        ? <div style={{ display: "flex", marginBottom: "6vw" }}>
                            <TeamChoiceButton onClick={() => setPopUpWindowForChoice(true)} currentCommand={currentCommand} />
                            {/* <InputBlock readOnly={true} indexStatus={true} placeholder={"Команда"} list={commandList} value={currentCommand} setValue={choiceCommand} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock> */}
                        </div>
                        : <div style={{ display: "flex", marginBottom: "2vw" }}>
                            {/* <div className={classes.text__style}>
                                <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В выпадающем списке представлены <br />все команды для оценивания</span>}>
                                    <span>Команда:</span>
                                </Tooltip>
                            </div> */}
                            <InputBlock placeholder={"Команда"} readOnly={true} indexStatus={true} list={commandList} value={currentCommand} setValue={choiceCommand} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                    }
                    {occasion && (currentCommandId && currentCommandId !== -1) && accordionBlock && accordionBlock}

                    {occasion && (currentCommandId && currentCommandId !== -1) && <CheckMarkBlock grades={grades} setGrades={setGrades} mass={JSON.parse(occasion.check_point_questions)} />}

                    {(currentCommandId && currentCommandId !== -1) &&
                        <div className={classes.repo__and__grade__style}>
                            {device === "phone"
                                ? <div className={classes.repo__style}>
                                    <Description obBlure={createOrUpdateRatingJuryCommand} value={comment} setValue={setComment} placeholder={"Отзыв по работе"} />
                                </div>
                                : <div className={classes.repo__style}>
                                    {/* <div className={classes.text__style}>
                                        <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>Краткое описание мероприятия <br />(что проходит, для кого рассчитано и тд.)</span>}>
                                            <span>Отзыв:</span>
                                        </Tooltip>
                                    </div> */}
                                    <Description placeholder={"Отзыв"} style={{ border: "solid 0.2vw var(--main-color-border)" }} obBlure={createOrUpdateRatingJuryCommand} value={comment} setValue={setComment} />
                                </div>
                            }
                        </div>
                    }
                    {(currentCommandId && currentCommandId !== -1) &&
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "end", marginTop: "2vw" }}>
                            <Button onClick={createOrUpdateRatingJuryCommand}>Сохранить</Button>
                        </div>
                    }
                    {device === 'phone' &&
                        <div className={classes.border__block}>
                            <div className={classes.text__style__table}>
                                {titlesData.length > 0 && titlesData.map((value, i) => <span className={classes.text__name__style} key={i} >{i + 1} - {value}</span>)}
                                {/* 1 - Концепт и функциональность<br />
                            2 - Объем проделанной работы<br />
                            3 - Командная работа<br /> 
                            4 - Использованные технологии<br />
                            5 - Техническое исполнение<br /> */}
                            </div>
                        </div>
                    }
                    {/* <div className={classes.border__block}> */}
                    {tableTitle.length > 0 && tableData.length > 0 && <TableForMark tableTitle={tableTitle} tableData={tableData} />}
                    {/* <Table className={classes.table__style} columns={tableTitle} data={tableData} /> */}
                    {/* </div> */}
                    <Notifications />
                </div >}
        </Loader >
    )
}

export default MarkWork