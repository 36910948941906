import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Case from "components/UI/case/case/Case";
import CaseContainer from "components/UI/case/caseContainer/CaseContainer";
import "styles/css/case.css"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import Button from "components/UI/buttons/button/Button"
import { useFetching } from 'components/hooks/useFetching';
import Loader from 'components/UI/loader/Loader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';
import TaskService from "services/TaskService";
import { useNavigate } from "react-router";
import { privateRoutNames, publickRoutNames } from "router";
import { newName } from "redux/slice/titleSlice";
import { setSidebarItem } from "redux/slice/sidebarItemSlice";
function CasePage() {

    const [cases, setCases] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingCase, loadingCase, errorCase] = useFetching(async () => {
        try {
            const res = await TaskService.getTask()
            setCases(res.map((item, i) => <Case key={i} index={i} {...item} />))
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    const [suggestCase, loadingSuggest, errorSuggest] = useFetching(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
            } else {
                navigate(`/private/${privateRoutNames.createCase}`)
            }
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        dispatch(changeLoaderStatus(false))
        fetchingCase()
        dispatch(newName(Strings.sidebarCase))

        dispatch(setSidebarItem(Strings.sidebarCase))
    }, [])

    return (
        <Loader>
            <div className="case-container">
                <div style={{ display: "flex", justifyContent: "end", marginBottom: "2vw" }}>
                    <Button onClick={suggestCase}>Предложить</Button>
                </div>
                {
                    cases.length > 0
                        ? <div className="container__item">
                            {cases}
                        </div>
                        : <CaseContainer text={Strings.caseText} />
                }
            </div >
        </Loader>
    );
}

export default CasePage;