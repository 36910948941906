import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from "./AcordionForGrade.module.css"
import TextField from '@mui/material/TextField';
import "./styleBlock.css"
import TableWithPart from './tableWithPart/TableWithPart';
import Button from '../buttons/button/Button';
import { useDeviceType } from 'components/hooks/useDeviceType';
import { NotificationManager } from 'react-notifications';
import Notifications from "components/UI/popupNotifications/Notifications";
import Strings from 'values/Strings';
import InputBlock from '../inputBlock/InputBlock';

export default function AcordionForGrade({ table, ...props }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const copy = (data) => {
        if (data) {
            navigator.clipboard.writeText(data)
            NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
        }
    }

    const device = useDeviceType()

    return (
        <div>
            {device === "phone" ?

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.style__acordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.style__summary}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Кейс: {props.caseName}
                        </Typography>
                        {/* <Typography sx={{ width: '66%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Кейс: {props.caseName}
                        </Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Кейс:</span> {props.caseName}
                        </Typography>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Категория:</span> {props.caseCategory}
                        </Typography>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Описание:</span>  {props.caseSpecification}
                        </Typography>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Ожидаемый результат:</span> {props.caseExpected}
                        </Typography>

                        <div className={classes.style__second__block}>
                            <TableWithPart table={table} />
                        </div>
                        <div className={classes.row__style}>
                            <InputBlock onClick={() => copy(props.presentation == null ? "-" : props.presentation)} placeholder={"Презентация команды"} value={props.presentation == null ? "-" : props.presentation} readOnly={true} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            {/* <TextField
                                onClick={() => copy(props.presentation == null ? "-" : props.presentation)}
                                id="outlined-read-only-input"
                                label="Презентация кейса"
                                defaultValue={props.presentation == null ? "-" : props.presentation}
                                InputProps={{
                                    readOnly: true,
                                }}
                                // size='small'

                                className={classes.style__presentation__block}
                            /> */}
                            <InputBlock onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)} placeholder={"Ссылка на GitVerse"} value={props.addational_material == null ? "-" : props.addational_material} readOnly={true} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>

                        {/* <TextField
                            id="outlined-read-only-input"
                            label="Доп. информация"
                            onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)}
                            defaultValue={props.addational_material == null ? "-" : props.addational_material}
                            InputProps={{
                                readOnly: true,
                            }}
                            // size='small'

                            className={classes.style__presentation__block}
                        /> */}

                    </AccordionDetails>
                </Accordion>


                :


                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.style__acordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.style__summary}
                    >

                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block}>
                            Кейс: {props.caseName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Категория:</span> {props.caseCategory}
                        </Typography>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Описание:</span>  {props.caseSpecification}
                        </Typography>
                        <Typography sx={{ width: '100%', flexShrink: 0 }} className={classes.style__text__for__block__case}>
                            <span className={classes.span__style__name}>Ожидаемый результат:</span> {props.caseExpected}
                        </Typography>

                        <div className={classes.style__second__block}>
                            <TableWithPart table={table} />
                        </div>
                        <div className={classes.row__style}>
                            <InputBlock onClick={() => copy(props.presentation == null ? "-" : props.presentation)} placeholder={"Презентация команды"} value={props.presentation == null ? "-" : props.presentation} readOnly={true} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            {/* <TextField
                                id="outlined-read-only-input"
                                label="Презентация команды"
                                onClick={() => copy(props.presentation == null ? "-" : props.presentation)}
                                defaultValue={props.presentation == null ? "-" : props.presentation}
                                InputProps={{
                                    readOnly: true,

                                }}
                                // size='small'

                                className={classes.style__presentation__block}
                            /> */}
                            <InputBlock onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)} placeholder={"Ссылка на GitVerse"} value={props.addational_material == null ? "-" : props.addational_material} readOnly={true} onListStatus={false}><img alt="" src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>

                        {/* <TextField
                            id="outlined-read-only-input"
                            label="Доп. информация"
                            onClick={() => copy(props.addational_material == null ? "-" : props.addational_material)}
                            defaultValue={props.addational_material == null ? "-" : props.addational_material}
                            InputProps={{
                                readOnly: true,
                            }}
                            // size='small'

                            className={classes.style__presentation__block}
                        /> */}
                    </AccordionDetails>
                </Accordion>

            }
            <Notifications />
        </div>

    );
}