import React from 'react';
import Loader from 'components/UI/loader/Loader';
import { Notifications } from 'react-notifications';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import NotificationManager from 'react-notifications/lib/NotificationManager';
import News from 'components/UI/mainPage/news/News';
import 'styles/css/main.css'
import 'styles/css/pop-up-window.css'
import Strings from 'values/Strings';
import { useFetching } from 'components/hooks/useFetching';
import NewsService from 'services/NewsService';
import { useState } from 'react';
import { changePopUpWindowStatus } from 'redux/slice/popUpWindowSlice';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';



function NewsPage() {

    const [newsList, setNewsList] = useState([])
    const dispatch = useDispatch()

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingNews, loadingNews, errorNews] = useFetching(async () => {
        try {
            const res = await NewsService.getPublishNews()
            await setNewsList(res.map((item, i) => <News key={i} {...item} />))
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchingNews()
        dispatch(changePopUpWindowStatus(false))

        dispatch(setSidebarItem(Strings.sidebarMain))
    }, [])



    return (
        <Loader>
            <div className={`news`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {newsList}
            </div>
            <Notifications />
        </Loader>
    );
}

export default NewsPage;
