import classes from "./AboutEvent.module.css"
import "@fontsource/roboto"
import { FcDocument } from 'react-icons/fc';
import { useDeviceType } from "components/hooks/useDeviceType";
import Button from "../buttons/button/Button";
import { useState } from "react";
import { useEffect } from "react";
import TitleMain from "../titleMain/TitleMain";
import Marquee from "react-fast-marquee";
import Edro from "assets/images/companyOriginal/edroL.svg"
// import Art from "assets/images/companyOriginal/artl.png"
// import Khmao from "assets/images/companyOriginal/khmao.png"
import SberL from "assets/images/companyOriginal/sberL.png"
// import Prior from "assets/images/companyOriginal/priorit.png"
import Sber from "assets/images/companyOriginal/sber.png"
import Git from "assets/images/companyOriginal/GitVerse.svg"
// import Surgu from "assets/images/companyOriginal/surguL.png"


const AboutEvent = ({ children, listAboutOccasion, participantPopUp, ...props }) => {

    const device = useDeviceType()
    const logo = [Edro, Sber, Git, Edro, Sber, Git] //[Edro, GazI, Art, Khmao, GazTr, Neft, Prior, Sber, Surgu, Kuchin, Week]

    return (

        <div className={classes.main__block__style__about}>
            <h3>{props.occasion}</h3>

            <div className={classes.first__block__about}>

                <div className={classes.up__text__style}>
                    <TitleMain text={"Информация о проведении"} className={classes.color__sber} />
                    {/* <span className={classes.text__style}>Информация о проведении мероприятия</span> */}
                    <div className={classes.date__time__place__style}>
                        <ul>
                            <li>
                                <span>Когда?  -  {props.date}</span>
                            </li>

                            <li>
                                <span>Во сколько?  -  {props.time}</span>
                            </li>

                            <li>
                                <span>Где?  -  {props.place}</span>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className={classes.up__text__style}>
                    <TitleMain text={"Описание мероприятия"} className={classes.color__sber} />
                    {/* <span className={classes.text__style}>Описание мероприятия</span> */}
                    <div className={classes.description__style}>
                        <span>{props.description}</span>
                    </div>
                </div>
            </div>

            <div className={classes.second__block__about}>


                <div className={classes.up__text__style}>
                    <TitleMain text={"События по времени"} className={classes.color__sber} />
                    {/* <span className={classes.text__style}>События по времени</span> */}
                    <div className={`${classes.time__management__style} ${classes.border__for__list}`}>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.time_managment}</span>
                    </div>
                </div>
            </div>

            <div className={classes.about__logo}>
                <Marquee speed={100} gradientWidth={"15vw"} gradientColor={"#FFF"} gradient={true}>
                    {logo.map((any, i) => <img key={i} src={any} alt="" className={classes.about__logo__img} />)}
                </Marquee>
            </div>


            <div className={classes.button__style}>
                <Button className={classes.button_sber} onClick={participantPopUp} color="#6FE1B5">Участвовать</Button>
            </div>


            <div className={classes.third__block__about}>
                <TitleMain text={"Документы мероприятия"} className={classes.color__sber} />
                {/* <span className={classes.docum__text__style}>Документы мероприятия</span> */}
                <div className={classes.doc__main__block}>
                    <div className={classes.document__style}>
                        <a href={props.minors}>
                            <FcDocument className={classes.icon__style} />
                            <span>Согласие на обработку ПДн несовершеннолетнего</span>
                        </a>
                    </div>

                    <div className={classes.document__style}>
                        <a href={props.condition}>
                            <FcDocument className={classes.icon__style} />
                            <span>Положение о хакатоне</span>
                        </a>

                    </div>

                    <div className={classes.document__style}>
                        <a href={props.criteria}>
                            <FcDocument className={classes.icon__style} />
                            <span>Порядок обработки ПДн</span>
                        </a>
                    </div>
                </div>

                {props.location &&
                    <div className={classes.block__map}>
                        <TitleMain text={"Место проведения мероприятия"} className={classes.color__sber} />
                        {/* <span>Место проведения мероприятия на карте</span> */}
                        <iframe src={props.location} frameBorder="0" className={classes.map__style}></iframe>
                    </div>
                }
            </div>

        </div >

    )
}

export default AboutEvent