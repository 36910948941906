import React, { useEffect, useState } from 'react';
import Loader from 'components/UI/loader/Loader';
import CertificateValid from 'components/UI/certificateValid/CertificateValid';
import SearchCertificate from 'components/UI/certificateValid/searchCertificate/SearchCertificate';
import { useFetching } from 'components/hooks/useFetching';
import CertificatService from 'services/CertificatService';
import { useNavigate, useParams } from 'react-router-dom';
import { newName } from 'redux/slice/titleSlice';
import { useDispatch } from 'react-redux';
import Strings from 'values/Strings';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { publickRoutNames } from 'router';

function CertificateValidate() {

    const dispath = useDispatch()
    const { id } = useParams()
    const [res, setRes] = useState([])
    const navigate = useNavigate()

    const [getInfoCertificate, loadinggetInfoCertificate, errorgetInfoCertificate] = useFetching(async (id) => {
        try {
            const res = await CertificatService.getInfoCertificate(id)
            if (res.length !== 0) {
                setRes(res)
            }
            else {
                NotificationManager.warning("Сертификат не обнаружен", Strings.notificationsErrorTitle, 5000);
                navigate(`/${publickRoutNames.mainInfo}`)
            }
        } catch (error) {
        }
    })

    useEffect(() => {
        getInfoCertificate(id)
        dispath(newName(Strings.pageCertificate))
    }, [id])


    return (
        <Loader>
            {res.length !== 0 && <CertificateValid data={res} />}
        </Loader>
    );
}

export default CertificateValidate;