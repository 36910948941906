import { useEffect } from "react";
import classes from './ReportUpload.module.css'
import { useFetching } from "components/hooks/useFetching";
import OccasionService from "services/OccasionService";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import Notifications from "components/UI/popupNotifications/Notifications";
import Strings from "values/Strings";
import ReportService from "services/ReportService";
import Loader from "components/UI/loader/Loader";
import ReportUploadContent from "./reportUploadContent/ReportUploadContent";

function ReportUpload() {

    const [occasions, setOccasions] = useState([])
    const [occasionList, setOccasionList] = useState([])
    const [link, setLink] = useState("")


    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasions()
            setOccasions(res)
            setOccasionList(res.map(item => item.name))
        } catch (error) {
            notificationError(error)
        }
    })

    const copyLinkToBuffer = (link) => {
        if (link?.length > 0) {
            if (document.hasFocus()) {
                navigator.clipboard.writeText(link)
                NotificationManager.success(Strings.notificationsReportCreateCopyLinkToBuffer, Strings.notificationsSuccessTitle, 2500);
            }
        }
    }

    const [createReport, loadingCreateReport, errorCreateReport] = useFetching(async (id) => {
        try {
            if (id.toString().length > 0) {
                const link = await ReportService.createReport({ id })
                setLink(link)
                copyLinkToBuffer(link)
                NotificationManager.success(Strings.notificationsReportCreate, Strings.notificationsSuccessTitle, 2500);
            } else {
                NotificationManager.warning(Strings.notificationsWarningDataEmptyNews, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchingOccasion()
    }, [])

    return (
        <div className={classes.reportUploadContainer}>
            <Loader>
                <ReportUploadContent
                    occasions={occasions}
                    occasionList={occasionList}
                    setOccasions={setOccasions}
                    setOccasionList={setOccasionList}
                    createReport={createReport}
                    link={link}
                    copyLinkToBuffer={copyLinkToBuffer}
                />
            </Loader>
            <Notifications />
        </div>
    );
}

export default ReportUpload;