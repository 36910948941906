import MentorCreateCase from "components/UI/mentorCreateCase/MentorCreateCase";
import 'styles/css/create-case.css'
import { useNavigate, useParams } from "react-router-dom"
import MentorUpdateCase from "components/UI/mentorCreateCase/MentorUpdateCase";
import TaskService from "services/TaskService";
import NotificationManager from "react-notifications/lib/NotificationManager"
import Strings from "values/Strings";
import { useEffect, useState } from "react";
import { useFetching } from "components/hooks/useFetching";
import { privateRoutNames } from "router";
import Loader from "components/UI/loader/Loader";
import { setSidebarItem } from "redux/slice/sidebarItemSlice";
import { useDispatch } from "react-redux";


function CreateAndUpdateCasePage() {
    const params = useParams()
    const nvaigate = useNavigate()
    const [originalUpdateCase, setOriginalUpdateCase] = useState({
        additional_material: "",
        category: "",
        contact_tg: "",
        expected_result: "",
        occasion: {},
        presentation: null,
        prize: "",
        specification: "",
        title: "",
        status: 3,
    });

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchTasks, loadingFetchTasks, fetchingTasksError] = useFetching(async (id) => {
        try {
            const task = await TaskService.getCurrentTask(id)
            setOriginalUpdateCase(task)
            if (task.status === 3) {
                nvaigate(`/private/${privateRoutNames.mentorCase}`)
                notificationError(Strings.notificationsErrorCaseWasRejected)
            }
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchTasks(params?.id)
    }, [params?.id])


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setSidebarItem(Strings.createCase))
    }, [])


    return (
        params?.id && originalUpdateCase.status !== 3 ?
            <Loader localStatus={loadingFetchTasks}>
                <div className="create-case-container">
                    <MentorUpdateCase originalUpdateCase={originalUpdateCase} taskId={params?.id} />
                </div>
            </Loader>
            : <div className="create-case-container">
                <MentorCreateCase />
            </div>
    );
}

export default CreateAndUpdateCasePage;