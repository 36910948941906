import classes from "./PartChange.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState } from 'react'
import Button from "components/UI/buttons/button/Button"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDispatch } from "react-redux"
import { useDeviceType } from "components/hooks/useDeviceType"
import AccountService from "services/AccountService"
import { useFetching } from "components/hooks/useFetching"
import Strings from "values/Strings"
import { NotificationManager } from "react-notifications"
import Notifications from "components/UI/popupNotifications/Notifications"
// import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import Part, { getPartByDescription } from "model/part"
import OccasionService from "services/OccasionService"
import { useEffect } from "react"
import PartService from "services/PartService"
import TitleMain from "components/UI/titleMain/TitleMain";
import TaskService from "services/TaskService"
import MentorService from "services/MentorService"

const PartChange = () => {

    const [account, setAccount] = useState([])
    const [accountList, setAccountList] = useState([])
    const [accountCurrent, setAccountCurrent] = useState({})

    const [occasion, setOccasion] = useState([])
    const [occasionList, setOccasionList] = useState([])
    const [occasionCurrent, setOccasionCurrent] = useState({})
    const [valueOccasion, setValueOccasion] = useState('')

    const [valueForSearch, setValueForSearch] = useState('')

    const [cases, setCases] = useState([])
    const [valueCase, setValueCase] = useState('')
    const [caseList, setCaseList] = useState([])
    const [caseCurrent, setCaseCurrent] = useState({})

    const [part, setPart] = useState('')
    const device = useDeviceType()

    const notificationError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            NotificationManager.error(`${error.response.data.message}`, Strings.notificationsErrorTitle, 5000);
        } else {
            NotificationManager.error(`${error.message}`, Strings.notificationsErrorTitle, 5000);
        }
    };

    const [searchingAccount, loadingAccount, errorAccount] = useFetching(async (data) => {
        if (valueForSearch.length > 2) {
            try {
                const res = await AccountService.searchUserByEmail(data)
                setAccount(res)
                setAccountList(res.map(item => item.email))
            } catch (error) {
                notificationError(error)
            }
        }
    }, false)

    // const throttledSearch = throttle(searchingAccount, 500);
    const debouncedSearch = debounce(searchingAccount, 500);

    const [fetchingCases, loadingFetchingCases, errorFetchingCases] = useFetching(async (id) => {
        try {
            const res = await TaskService.getCurrentTasksByOccasionId(id)
            setCases(res)
            setCaseList(res.map(item => item.title))
        } catch (error) {
            notificationError(error)
        }
    }, false)

    const [fetchingOccasion, loadingOccasion, errorOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getCurrentOccasions()
            setOccasion(res)
            setOccasionList(res.map(item => item.name))
        } catch (error) {
            notificationError(error)
        }
    }, false)


    useEffect(() => {
        fetchingOccasion()
        return () => debouncedSearch.cancel();
    }, [])

    const [changePartForAccount, loadingAccountPartForAccount, errorAccountPartForAccount] = useFetching(async () => {
        try {
            const _part = getPartByDescription(part)
            const data = { occasion: occasionCurrent.id, account: accountCurrent.id, part: _part.id }
            const res = await PartService.changePartForAccount(data)
            if (res) {
                NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            notificationError(error)
        }
    }, false)
    const [assigningAcaseToAMentor, loadingAssigningAcaseToAMentor, errorAssigningAcaseToAMentor] = useFetching(async () => {
        try {
            const _part = getPartByDescription(part)
            const data = { task: caseCurrent.id, account: accountCurrent.id, part: _part }
            const res = await MentorService.updateMentor(data)
            if (res) {
                NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            notificationError(error)
        }
    }, false)

    function choiceAccount(data, index = -1) {
        // throttledSearch(data);
        debouncedSearch(data);
        if (index !== -1) {
            setValueForSearch(account[index].email)
            setAccountCurrent(account[index])
        } else {
            setValueForSearch(data)
        }
    }

    function choiceOccasion(data, index = -1) {
        if (index !== -1) {
            fetchingCases(occasion[index].id)
            setValueOccasion(occasion[index].name)
            setOccasionCurrent(occasion[index])
        } else {
            setValueOccasion(data)
        }
    }

    function choiceCase(data, index = -1) {
        if (index !== -1) {
            setValueCase(cases[index].title)
            setCaseCurrent(cases[index])
        } else {
            setValueCase(data)
        }
    }

    function choicePart(data, index) {
        setPart(data)
    }


    return (
        <div className={classes.events__create__style}>
            <TitleMain text={"Назначение ролей"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
            <ul>
                {(device === "phone")
                    ? <li>
                        <InputBlock localRef={true} readOnly={true} placeholder={"Мероприятие"} indexStatus={true} onListStatus={true} list={occasionList} value={valueOccasion} setValue={choiceOccasion}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    : <li>
                        <InputBlock placeholder={"Мероприятие"} localRef={true} readOnly={true} indexStatus={true} onListStatus={true} list={occasionList} value={valueOccasion} setValue={choiceOccasion}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }
                {(device === "phone")
                    ? <li>
                        <InputBlock localRef={true} placeholder={"Название"} indexStatus={true} onListStatus={true} list={accountList} value={valueForSearch} setValue={choiceAccount}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    : <li>
                        <InputBlock placeholder={"Почта"} localRef={true} indexStatus={true} onListStatus={true} list={accountList} value={valueForSearch} setValue={choiceAccount}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }
                {(Object.values(accountCurrent).length > 0) && ((device === "phone")
                    ? <li>
                        <InputBlock filter={true} localRef={true} placeholder={"Роль"} onListStatus={true} list={Object.values(Part).map(item => item.description)} value={part ? part : ''} setValue={choicePart}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    : <li>
                        <InputBlock placeholder={"Роль"} filter={true} localRef={true} onListStatus={true} list={Object.values(Part).map(item => item.description)} value={part ? part : ''} setValue={choicePart}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                )}
                {(Object.values(accountCurrent).length > 0) && cases.length > 0 && Object.keys(occasionCurrent).length > 0 && ((device === "phone")
                    ? <li>
                        <InputBlock localRef={true} readOnly={true} placeholder={"Кейс"} indexStatus={true} onListStatus={true} list={caseList} value={valueCase} setValue={choiceCase}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    : <li>
                        <InputBlock placeholder={"Кейс"} localRef={true} readOnly={true} indexStatus={true} onListStatus={true} list={caseList} value={valueCase} setValue={choiceCase}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                )}
            </ul>
            <div className={classes.buttonContainer}>
                <Button onClick={changePartForAccount} className={classes.button}>Назначить роль</Button>
                {Object.keys(caseCurrent).length > 0 && <Button onClick={assigningAcaseToAMentor} className={classes.button}>Закрепить кейс</Button>}
            </div>
            <Notifications />
        </div >
    )
}

export default PartChange