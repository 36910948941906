import classes from "./Board.module.css"
import Colors from "values/Colors"
import "@fontsource/roboto"
import Strings from "values/Strings"
import CaseContainer from "../case/caseContainer/CaseContainer"

const Board = ({ data, ...props }) => {


    return (

        data[0]?.status === 1 ?
            <div className={classes.board}>
                <span>{Strings.boardData}</span>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }} scope="col">Место</th>
                            <th scope="col">Команда</th>
                            <th scope="col">Кейс</th>
                            <th scope="col">Баллы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) =>
                            <tr>
                                <td style={{ width: "10%", color: index === 0 ? Colors.markGreen : index === 1 ? Colors.markOrange : index === 2 && Colors.markRed }}>{index + 1}</td>
                                <td >{item.command}</td>
                                <td>{item.case}</td>
                                <td>{item.grade}</td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
            :
            <CaseContainer text={Strings.boardWithOutData} />



    )
}

export default Board