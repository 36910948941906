import { useEffect, useState } from 'react';
import OccasionContainer from 'components/UI/eventContainer/OccasionContainer'
import "styles/css/event.css"
import "styles/css/pop-up-window.css"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from 'components/hooks/useDeviceType';
import OccasionService from 'services/OccasionService';
import { useFetching } from 'components/hooks/useFetching';
import Loader from 'components/UI/loader/Loader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Notifications from 'components/UI/popupNotifications/Notifications';
import Strings from 'values/Strings';
import ParticipantService from 'services/ParticipantService';
import { useNavigate } from 'react-router-dom';
import { privateRoutNames, publickRoutNames } from 'router';
import TicketParticipation from 'components/UI/ticketParticipation/TicketParticipation';
import AccountService from 'services/AccountService';
import { changePopUpWindowStatus } from 'redux/slice/popUpWindowSlice';
import CommandService from 'services/CommandService';
import СonfirmationParticipation from 'components/UI/confirmationParticipation/СonfirmationParticipation'
import { newName } from 'redux/slice/titleSlice';
import CaseContainer from 'components/UI/case/caseContainer/CaseContainer';
import OAuthService from 'services/OAuthService';
import RoleService from 'services/RoleService';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';


function OccasionPage() {

    const [occasions, setOccasions] = useState([])
    const dispatch = useDispatch()
    const device = useDeviceType()
    const navigate = useNavigate()
    const [occasion, setOccasion] = useState('')
    const cameraData = useSelector(state => state.camera.data)
    const popUpWindowStatus = useSelector(state => state.popUpWindow.status)
    const [eventName, setEventName] = useState('')
    const [userInfo, setUserInfo] = useState({
        id: '', birthday: '', educational_institution: '',
        gender: '', group_class: '', learning_status: '',
        name: '', patronymic: '', phone: '', status: '',
        surname: '', email: '', to_char: ''
    });
    const [role, setRole] = useState([])
    const [tokenInfo, setTokenInfo] = useState({ tokenType: '', token: '' })
    const [commandData, setCommandData] = useState({ name: '', count_participant: '' })

    const [fethcingRole, loadingRole, errorRole] = useFetching(async () => {
        try {
            const res = await RoleService.getRoles()
            setRole(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const [getInfoAccount, loadinggetInfo, errorgetInfo] = useFetching(async (id) => {
        try {
            const res = await AccountService.getAccount(id)
            setUserInfo(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const participantPopUp = (occasion, eventName) => {
        setEventName(eventName)
        setOccasion(occasion)
        const { user } = OAuthService.decryptedSync('userData')
        if (user?.id) {
            dispatch(changePopUpWindowStatus(true))
            getInfoAccount(user?.id)
        } else {
            navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
        }
    }


    const [preRegistration, loadingPreRegistration, errorPreRegistration] = useFetching(async (currentRole) => {
        try {
            if (currentRole === undefined || currentRole === null) {
                notificationWarning(`Выберите роль!`)
            }
            else if (userInfo.name.length === 0 || userInfo.surname.length === 0) {
                notificationWarning(`Поля ФИО не должны быть пустыми \n Перейдите в Ваш "Профиль" для заполнения`)
            }
            else {
                const accessToken = localStorage.getItem('accessToken')
                if (!accessToken) {
                    navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
                }
                const res = await ParticipantService.preRegistration({
                    occasion, participant_role: currentRole.id
                })
                if (res.status === 404) {
                    NotificationManager.warning(res.message, Strings.notificationsWarningTitle, 4000);
                }
                else {
                    NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
                }
                dispatch(changePopUpWindowStatus(false))
                dispatch(changeLoaderStatus(false))
            }
        } catch (error) {
            notificationError(error)
        }
    })


    const [fetchingOccasions, loadingOccasions, errorOccasions] = useFetching(async () => {
        try {
            const res = await OccasionService.getCurrentOccasions()
            await setOccasions(res.map((item, i) => <OccasionContainer participantPopUp={participantPopUp} key={i} {...item} />))
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    const goToCommandRoom = (data) => {
        localStorage.setItem('command', data)
        // dispatch(setCommand(data))
        dispatch(newName(Strings.teamRoom))
        dispatch(changePopUpWindowStatus(false))
        navigate(`/private/${privateRoutNames.teamRoom}`)
    }


    const [createCommand, loadingCommand, errorCommand] = useFetching(async (data) => {
        try {
            if (userInfo.name.length === 0 || userInfo.surname.length === 0) {
                notificationWarning(`Поля ФИО не должны быть пустыми \n Перейдите в Ваш "Профиль" для заполнения`)
            }
            else if (data.name.length === 0) {
                notificationWarning(`Название команды не может быть пустым`)
            }
            else if (parseInt(data.count_participant) > 6 || data.count_participant.length === 0) {
                notificationWarning(`Команда должна состоять от 1 до 5 человек`)
            }
            else {
                const accessToken = localStorage.getItem('accessToken')
                if (!accessToken) {
                    navigate(`/${publickRoutNames.main}`, { state: { auth: true } })
                }
                const res = await CommandService.createCommand({ occasionToken: tokenInfo.token, ...data })
                if (res.status === 404) {
                    notificationWarning(res.message)
                } else {
                    goToCommandRoom(res)
                }
            }
        } catch (error) {
            notificationError(error)
        }
    })


    useEffect(() => {
        dispatch(newName(Strings.sidebarOccasion))
        fetchingOccasions();
        fethcingRole()

        dispatch(setSidebarItem(Strings.sidebarOccasion))
    }, [])

    const closePopUpWindows = () => {
        dispatch(changePopUpWindowStatus(false))
        setUserInfo({
            id: '', birthday: '', educational_institution: '',
            gender: '', group_class: '', learning_status: '',
            name: '', patronymic: '', phone: '', status: '',
            surname: '', email: '', to_char: ''
        })
    }


    return (
        <Loader>
            {(occasions.length > 0) ?
                <div className='event-container'>
                    {(popUpWindowStatus && (eventName === 'participate')) &&
                        <div className="pop-up">
                            <div className="pop-up__container">
                                <TicketParticipation role={role} preRegistration={preRegistration} userInfo={userInfo} close={() => dispatch(changePopUpWindowStatus(false))} />
                            </div>
                        </div>
                    }
                    {(popUpWindowStatus && tokenInfo.tokenType === 'occasionToken' && (userInfo?.name.length > 0 || userInfo?.surname.length > 0)) &&
                        <div className="pop-up">
                            <div className="pop-up__container">
                                <СonfirmationParticipation close={closePopUpWindows}
                                    createCommand={createCommand} userInfo={userInfo}
                                    commandData={commandData} setCommandData={setCommandData}
                                />
                            </div>
                        </div>
                    }
                    {(popUpWindowStatus && tokenInfo.tokenType === 'commandToken' && (userInfo?.name.length > 0 || userInfo?.surname.length > 0)) &&
                        <div className="pop-up">
                            <div className="pop-up__container">
                                <TicketParticipation role={role} commandName={userInfo?.commandname?.name} close={closePopUpWindows}
                                    userInfo={userInfo} preRegistration={preRegistration}
                                />
                            </div>
                        </div>
                    }
                    <div className={`${popUpWindowStatus && 'pop-up-blure'}`}>
                        {device === "phone"
                            ? <div className="event-container">
                                {occasions}
                            </div>
                            :
                            <div className="event-container">
                                {occasions}
                            </div>
                        }
                    </div>
                </div> : <CaseContainer text={Strings.occasionText} />}
            <Notifications />
        </Loader>
    );
}

export default OccasionPage;